import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import en from './Text/en.json';
import es from './Text/es.json';
import fr from './Text/fr.json';
import fil from './Text/fil.json';

const resources = {
  en: en,
  es: es,
  fr: fr,
  fil: fil
};

i18n
  .use(detector)
  .use(initReactI18next) 
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      order: ['acceptLanguage', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage'],
      lookupAcceptLanguage: true // Ensure the detector looks for the Accept-Language header
    },
    keySeparator: false, 
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
