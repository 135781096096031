import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../store/user-context';

const LanguageSelector = ({ customClass, onChange }) => {
  const { i18n } = useTranslation();
  const { state } = useUser();
  const { parent } = state;

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <select
      className={customClass}
      onChange={changeLanguage}
      defaultValue={(parent && parent.locale) ? parent.locale : i18n.language}
      aria-label="Language Selector"
    >
      <option value="en">
        English
      </option>
      <option value="es">Español</option>
      <option value="fr">Français</option>
      <option value="fil">Filipino</option>
    </select>
  );
};

export default LanguageSelector;
